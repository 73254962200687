<template>
  <v-card>
    <v-card-title>
      <span class="headline"> Información del cupón </span>
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
          snackbarText
        }}</v-snackbar>
        <v-row>
          <v-col class="mb-5" cols="12" sm="12" md="12">
            <h2 style="color: green">
              {{
                coupon.isFixed ? currency(coupon.amount) : `${coupon.amount}%`
              }}
              de descuento aplicado al
              {{ coupon.type | filterCouponType }} de la orden.
            </h2>
          </v-col>

          <v-col cols="12" sm="4" md="4">
            <span class="d-block font-weight-bold"> Código </span>
            <p class="ml-2 mt-2">
              {{ coupon.code }}
            </p>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <span class="d-block font-weight-bold"> Mínimo de compra </span>
            <p v-if="coupon.minimumSale > 0" class="ml-2 mt-2">
              {{ currency(coupon.minimumSale) }}
            </p>
            <p class="all mt-2" v-if="coupon.minimumSale == 0">
              No hay mínimo de compra.
            </p>
          </v-col>

          <v-col cols="12" sm="4" md="4">
            <span class="d-block font-weight-bold">
              Descuento máximo (Techo)
            </span>
            <p v-if="coupon.maximumDiscount > 0" class="ml-2 mt-2">
              {{ currency(coupon.maximumDiscount) }}
            </p>
            <p class="all" v-if="coupon.maximumDiscount == 0">
              No hay máximo descuento.
            </p>
          </v-col>

          <v-col cols="12" sm="4" md="4">
            <span class="d-block font-weight-bold"> Inventario </span>
            <p class="ml-2 mt-2">
              {{ coupon.inventory }}
            </p>
          </v-col>

          <v-col cols="12" sm="4" md="4">
            <span class="d-block font-weight-bold"> Reclamados </span>
            <p class="ml-2 mt-2">
              {{ coupon.claimed }}
            </p>
          </v-col>

          <v-col cols="12" sm="4" md="4">
            <span class="d-block font-weight-bold"> Utilizados </span>
            <p class="ml-2 mt-2">
              {{ coupon.used }}
            </p>
          </v-col>

          <v-col class="ml-2 mt-n5" cols="12" sm="12" md="12">
        
            <v-row class="mt-4">
              <v-col cols="12" sm="4" md="4">
                <span class="d-block font-weight-bold"> Fecha inicio </span>
                <p class="ml-2 mt-2">{{ coupon.from | filterDate }}</p>
              </v-col>

              <v-col cols="12" sm="4" md="4">
                <span class="d-block font-weight-bold"> Fecha final </span>
                <p class="ml-2 mt-2">{{ coupon.to | filterDate }}</p>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <span class="d-block font-weight-bold"> Duración </span>
                <p class="ml-2 mt-2">
                  {{ (coupon.to - coupon.from) | filterDuration }}
                </p>
              </v-col>

              <v-col cols="12" sm="4" md="4">
                <span class="d-block font-weight-bold mb-4">
                  Métodos de pago
                </span>
                <p class="mr-3" v-for="(item, id) of paymentGateways" :key="id">
                  - {{ item.name }}
                </p>

                <p
                  class="all"
                  v-if="
                    !coupon.paymentGateways || !coupon.paymentGateways.length
                  "
                >
                  Aplica para todos los métodos de pago.
                </p>
              </v-col>

              <v-col cols="12" sm="4" md="4">
                <span class="d-block font-weight-bold mb-4"> Categorías </span>
                <p class="mr-3" v-for="(item, id) of categories" :key="id">
                  - {{ item.name }}
                </p>

                <p class="all" v-if="!coupon.categories.length">
                  Aplica para todas las categorías.
                </p>
              </v-col>

              <v-col cols="12" sm="4" md="4">
                <span class="d-block font-weight-bold mb-4"> Comercios </span>
                <p class="mr-3" v-for="(item, id) of businesses" :key="id">
                  - {{ item.shortName }}
                </p>

                <p
                  class="all"
                  v-if="!coupon.businesses || !coupon.businesses.length"
                >
                  Aplica para todos los comercios.
                </p>
              </v-col>

              <!-- <v-col cols="12" sm="4" md="4">
                <span class="d-block font-weight-bold mb-4"> Usuarios </span>
                <p class="mr-3" v-for="(item, id) of users" :key="id">
                  - {{ item.name }} {{ item.surname }}
                  <small>({{ item.phone }})</small>
                </p>

                <p class="all" v-if="!coupon.users || !coupon.users.length">
                  Aplica para todos los usuarios.
                </p>
              </v-col> -->

              <v-col cols="12" sm="4" md="4">
                <span class="d-block font-weight-bold mb-4"> Tarjetas </span>
                <p class="mr-3" v-for="(item, id) of coupon.bins" :key="id">
                  - {{ item }}
                </p>

                <p class="all" v-if="!coupon.bins || !coupon.bins.length">
                  Aplica para todas las tarjetas.
                </p>
              </v-col>
            </v-row>

             <v-row v-if="coupon.description || coupon.disclaimer">
              <v-col v-if="coupon.description" cols="12" sm="12" md="12">
                <div>
                  <span class="d-block font-weight-bold"> Descripción </span>
                  <p class="ml-2 mt-2">{{ coupon.description }}</p>
                </div>
              </v-col>
              <v-col v-if="coupon.disclaimer" cols="12" sm="12" md="12">
                <div>
                  <span class="d-block font-weight-bold"> Restricciones </span>
                  <p class="ml-2 mt-2">{{ coupon.disclaimer }}</p>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { db, fb } from "@/firebase";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default {
  props: ["coupon"],
  components: {},
  data() {
    return {
      snackbarText: "",
      snackbar: false,
      paymentGateways: [],
      categories: [],
      loading: true,
      businesses: [],
      users: [],
    };
  },
  filters: {
    filterCouponType(type) {
      switch (type) {
        case "shipping":
          return "ENVÍO";
        case "subtotal":
          return "SUBTOTAL";
        case "total":
          return "TOTAL";
      }
    },
    filterDuration(miliseconds) {
      let days = Math.round(miliseconds / 86400);
      return `${days} ${days == 1 ? "día" : "días"}`;
    },
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD MMMM YYYY");
    },
  },
  methods: {
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
  },
  async mounted() {
    if (this.coupon.paymentGateways && this.coupon.paymentGateways.length) {
      await this.$binding(
        "paymentGateways",
        db
          .collection(`paymentGateways`)
          .where(
            fb.firestore.FieldPath.documentId(),
            "in",
            this.coupon.paymentGateways
          )
      );
    }

    if (this.coupon.categories && this.coupon.categories.length) {
      await this.$binding(
        "categories",
        db
          .collection(`categories`)
          .where(
            fb.firestore.FieldPath.documentId(),
            "in",
            this.coupon.categories
          )
      );
    }

    if (this.coupon.businesses && this.coupon.businesses.length) {
      await this.$binding(
        "businesses",
        db
          .collection(`businesses`)
          .where(
            fb.firestore.FieldPath.documentId(),
            "in",
            this.coupon.businesses
          )
      );
    }

    // if (this.coupon.users && this.coupon.users.length) {
    //   await this.$binding(
    //     "users",
    //     db
    //       .collection(`users`)
    //       .where(fb.firestore.FieldPath.documentId(), "in", this.coupon.users)
    //   );
    // }

    this.loading = false;
  },
  //   filters: {},
};
</script>


<style scoped>
span {
  font-size: 16px !important;
}

p {
  text-align: justify;
}

.all {
  color: rgb(3, 90, 3);
}
</style>
