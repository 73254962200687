<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-card :loading="formLoading">
      <v-card-title class="headline">
        Reporte de Cupones
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          :loading="formLoading"
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text style="font-size: 15px" class="mt-1">
        <v-container class="pa-3" fluid>
          <v-row>
            <v-col cols="12" md="12">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value="selectedDate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="selectedDate"
                    label="Seleccionar rango de fecha"
                    prepend-icon="fa-calendar-alt"
                    readonly
                    filled
                    rounded
                    hide-details
                    :disabled="formLoading"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="selectedDate"
                  range
                  :title-date-format="title"
                  scrollable
                  :max="today"
                >
                  <v-row no-gutters style="flex-wrap: nowrap">
                    <v-btn
                      class="flex-grow-1 flex-shrink-0"
                      large
                      rounded
                      outlined
                      color="primary"
                      @click="modal = false"
                      >Cancelar</v-btn
                    >
                    <v-btn
                      class="flex-grow-1 flex-shrink-0"
                      large
                      rounded
                      color="primary"
                      @click="$refs.dialog.save(selectedDate)"
                    >
                      Aceptar
                    </v-btn>
                  </v-row>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" sm="12">
              <v-select
                v-model="selectedCities"
                :items="cities"
                multiple
                prepend-icon="fa-city"
                label="Seleccionar ciudad"
                item-text="name"
                item-value=".key"
                aria-autocomplete="false"
                autocomplete="off"
                hide-details
                filled
                rounded
                :allow-overflow="false"
                clearable
              >
              </v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-row align="center" class="d-flex mb-4 mx-4">
          <v-btn
            rounded
            class="flex-grow-1"
            large
            :elevation="0"
            :minHeight="56"
            :loading="formLoading"
            @click="$emit('cancel')"
            >Cancelar</v-btn
          >
          <v-btn
            color="primary"
            rounded
            class="flex-grow-1 ml-2 white--text"
            large
            :elevation="0"
            :minHeight="56"
            @click="generate"
            :loading="formLoading"
            >Generar</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>
  
  <script>
import { mapState } from "vuex";
import { db, fb } from "@/firebase";
import axios from "axios";

export default {
  name: "coupons-report",
  props: ["city"],
  data() {
    return {
      formLoading: true,
      snackbarText: "",
      snackbar: false,
      modal: false,
      selectedCities: [],
      cities: [],
      selectedDate: [],
    };
  },

  computed: {
    ...mapState(["user"]),

    today() {
      return new Date().toISOString().substr(0, 10);
    },
  },

  methods: {
    checkNotObject(data) {
      return typeof data.item !== "object";
    },
    title() {
      return "Selecciona las fechas a filtrar";
    },

    getSectionToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },

    async generate() {
      if (!this.selectedDate[0] || !this.selectedDate[1]) {
        this.snackbarText = "Debe seleccionar un rango de fechas.";
        this.snackbar = true;
        return;
      }
      this.formLoading = true;
      let token = await this.getSectionToken();

      let data = {
        startDate: this.selectedDate[0],
        endDate: this.selectedDate[1],
        cities: this.selectedCities ?? [],
      };

      axios
        .post(
          `${process.env.VUE_APP_FUNCTIONS_URL}/callableReportsCoupons`,
          data,
          {
            headers: { Authorization: `Basic ${token}` },
            responseType: "blob",
          }
        )
        .then((response) => {
          this.formLoading = false;
          const { data, headers } = response;
          const fileName = `Reporte cupones ${this.selectedDate[0]} al ${this.selectedDate[1]}.xlsx`;

          const blob = new Blob([data], { type: headers["content-type"] });
          let dom = document.createElement("a");
          let url = window.URL.createObjectURL(blob);
          dom.href = url;
          dom.download = decodeURI(fileName);
          dom.style.display = "none";
          document.body.appendChild(dom);
          dom.click();
          dom.parentNode.removeChild(dom);
          window.URL.revokeObjectURL(url);
          // this.$emit("cancel");
        })
        .catch((err) => {
          console.log(err);
          this.formLoading = false;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },
  },

  async mounted() {
    this.formLoading = false;

    if (this.city) {
      this.selectedCities.push(this.city);
    }

    this.$binding(
      "cities",
      db
        .collection("cities")
        .where("deleted", "==", false)
        .orderBy("name", "asc")
    );
  },
};
</script>