<template>
  <v-container fluid class="px-2">
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-row class="pa-0 ma-0">
      <v-col cols="6">
        <h1>Cupones</h1>
      </v-col>
      <v-col cols="6" style="display: flex; justify-content: flex-end">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="rgba(0, 0, 0, 0.6)"
              fab
              v-on="on"
              class="ml-2"
              dark
              small
              :elevation="0"
              :loading="loading"
              @click="reportsDialog = true"
            >
              <v-icon x-large dark>fa-file-excel</v-icon>
            </v-btn>
          </template>
          <span>Exportar .xlsx</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6" class="py-0">
        <v-select
          v-model="selectedCountry"
          :items="countries"
          placeholder="Escoge el pais"
          outlined
          item-text="name"
          item-value=".key"
          rounded
        />
      </v-col>

      <v-col cols="12" md="6" class="py-0">
        <v-select
          v-model="selectedCity"
          :items="cities"
          placeholder="Escoge la ciudad"
          outlined
          :disabled="!selectedCountry"
          item-text="name"
          item-value=".key"
          rounded
        />
      </v-col>
      <v-col cols="12" class="py-0">
        <v-row justify="end">
          <v-btn
            color="primary"
            class="ma-2 white--text"
            :disabled="!selectedCity"
            :loading="loading"
            @click="newCoupon"
          >
            <v-icon right dark class="mr-3">fas fa-plus</v-icon>
            Crear nuevo cupón
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="getCoupons"
          :items-per-page="10"
          :loading="loading"
          :search="$store.state.search"
          :sort-desc="true"
          item-key=".key"
          sort-by="createdAt"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div class="inner-lottie">
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>

          <template v-slot:[`item.code`]="{ item }">
            <v-tooltip right v-if="item.code">
              <template v-slot:activator="{ on }">
                <v-chip
                  :color="
                    $vuetify.theme.dark
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(0, 0, 0, 0.1)'
                  "
                  style="display: inline-block"
                  v-on="on"
                  dark
                  :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                  @click="copyToClipboard(item.code)"
                >
                  {{ item.code }}
                </v-chip>
              </template>
              <i class="far fa-copy mr-2"></i>
              <span>Click para copiar</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.from`]="{ item }">
            {{ item.from | filterDate }}
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <p class="mb-0 pb-0">
              {{ item.name }}
            </p>

            <small
              class="font-weight-bold mt-0 pt-0"
              style="color: #FF1744"
              v-if="item.source"
              >Cuponera corporativa</small
            >
          </template>

          <template v-slot:[`item.to`]="{ item }">
            {{ item.to | filterDate }}
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            <p class="mt-4" v-if="item.isFixed">{{ item.amount | currency }}</p>
            <p class="mt-4" v-else>{{ `${item.amount}%` }}</p>
          </template>

          <template v-slot:[`item.duration`]="{ item }">
            {{ (item.to - item.from) | filterDuration }}
          </template>

          <template v-slot:[`item.type`]="{ item }">
            {{ item.type | filterCouponType }}
          </template>

          <template v-slot:[`item.isFixed`]="{ item }">
            {{ item.isFixed ? "Fijo" : "Porcental" }}
          </template>

          <template v-slot:[`item.categories`]="{ item }">
            <span v-if="item.categories">
              {{ item.categories.length }}
              <i
                v-if="item.categories.length > 0"
                @click="handleModalSummary('categories', item.categories)"
                class="fas fa-eye ml-3"
                style="color: gray; cursor: pointer"
              ></i>
            </span>
          </template>

          <template v-slot:[`item.businesses`]="{ item }">
            <span v-if="item.businesses">
              {{ item.businesses.length }}
              <i
                v-if="item.businesses.length > 0"
                @click="handleModalSummary('businesses', item.businesses)"
                class="fas fa-eye ml-3"
                style="color: gray; cursor: pointer"
              ></i>
            </span>
          </template>

          <template v-slot:[`item.users`]="{ item }">
            <span v-if="item.users">
              {{ item.users.length }}
              <i
                v-if="item.users.length > 0"
                @click="handleModalSummary('users', item.users)"
                class="fas fa-eye ml-3"
                style="color: gray; cursor: pointer"
              ></i>
            </span>
          </template>

          <template v-slot:[`item.businessName`]="{ item }">
            {{ item.businessName }}
          </template>

          <template v-slot:[`item.available`]="{ item }">
            {{ item.inventory - item.claimed }}
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { active: item.active },
                  item['.key'],
                  item
                )
              "
              :loading="loading"
              v-model="item.active"
            ></v-switch>
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="showInformation(item)"
                  class="mx-1"
                  fab
                  v-on="on"
                  :loading="loading"
                  dark
                  x-small
                  :elevation="0"
                  color="#5774a8"
                >
                  <v-icon dark>fa-info</v-icon>
                </v-btn>
              </template>
              <span>Información</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="downloadReport(item)"
                  class="mx-1"
                  :loading="loading"
                  fab
                  v-on="on"
                  dark
                  x-small
                  :elevation="0"
                  color="#227447"
                >
                  <v-icon dark>fa-file-excel</v-icon>
                </v-btn>
              </template>
              <span>Reporte de usos</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog scrollable persistent v-model="modalAddEdit" max-width="1000px">
      <add-edit-coupon
        :type="type"
        :city="selectedCity"
        :country="selectedCountry"
        @cancel="modalAddEdit = false"
        @success="handleSuccess"
        :key="randomKey"
        :item="item"
      ></add-edit-coupon>
    </v-dialog>

    <v-dialog persistent v-model="modalSummary" max-width="700px">
      <Summary
        :summaryCollection="summaryCollection"
        :idsArray="idsArray"
        :key="randomKey"
        @cancel="modalSummary = false"
      ></Summary>
    </v-dialog>

    <v-dialog
      persistent
      v-model="informationDialog"
      v-if="informationDialog"
      max-width="80%"
    >
      <Info :coupon="selectedCoupon" @cancel="informationDialog = false"></Info>
    </v-dialog>

    <v-dialog
      persistent
      v-model="reportsDialog"
      v-if="reportsDialog"
      max-width="600"
    >
      <report :city="selectedCity" @cancel="reportsDialog = false"></report>
    </v-dialog>
  </v-container>
</template>

<script>
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import { db, fb } from "@/firebase";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import AddEditCoupon from "./AddEditCoupon";
import Summary from "./Summary";
import Info from "./Information.vue";
import report from "./report.vue";
import axios from "axios";

export default {
  name: "coupons3",
  components: {
    lottie: Lottie,
    AddEditCoupon,
    Summary,
    Info,
    report,
  },
  mixins: [lottieSettings],
  data() {
    return {
      loading: true,
      saving: false,
      snackbar: false,
      snackbarText: "",
      coupons: [],
      cities: [],
      selectedCity: "",
      countries: [],
      selectedCountry: "",
      item: null,
      randomKey: 0,
      modalAddEdit: false,
      modalSummary: false,
      type: "",
      summaryCollection: "",
      selectedCoupon: null,
      informationDialog: false,
      reportsDialog: false,
      corporateBusiness: [],
      idsArray: [],
      users: [],
      headers: [
        {
          text: "Nombre",
          value: "name",
          width: "250px",
          class: "nowrap",
        },
        {
          text: "Código",
          value: "code",
          width: "150px",
          class: "nowrap",
        },

        {
          text: "Creado por",
          value: "businessName",
          width: "150px",
          class: "nowrap",
        },
        {
          text: "Inicia",
          value: "from",
          class: "nowrap",
        },
        {
          text: "Termina",
          value: "to",
          class: "nowrap",
        },

        {
          text: "Aplica a ",
          value: "type",
          width: "100px",
          class: "nowrap",
        },
        {
          text: "Beneficio",
          value: "isFixed",
          class: "nowrap",
        },
        {
          text: "Monto",
          value: "amount",
          class: "nowrap",
        },

        {
          text: "Usuarios",
          value: "users",
          class: "nowrap",
        },

        {
          text: "Inventario",
          value: "inventory",
          class: "nowrap",
        },
        {
          text: "Reclamados",
          value: "claimed",
          class: "nowrap",
        },
        {
          text: "Disponibles",
          value: "available",
          class: "nowrap",
        },

        {
          text: "Usos",
          value: "used",
          class: "nowrap",
        },

        // {
        //   text: "Categorías",
        //   value: "categories",
        //   class: "nowrap",
        // },
        // {
        //   text: "Comercios",
        //   value: "businesses",
        //   class: "nowrap",
        // },
        // {
        //   text: "Usuarios",
        //   value: "users",
        //   class: "nowrap",
        // },

        // {
        //   text: "Aplica",
        //   value: "applyDiscountTo",
        //   class: "nowrap",
        // },
        {
          text: "Activo",
          value: "active",
          class: "nowrap",
        },
        {
          text: "Creado por",
          value: "createdByName",
          class: "nowrap",
          width: "250px",
        },
        {
          value: "options",
          class: "nowrap",
          width: "150px",
          align : "end"
        },
      ],
    };
  },
  async mounted() {
    this.$store.commit("setSearchTerm", "cupones");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;

    try {
      await this.$binding(
        "countries",
        db.collection("countries").where("deleted", "==", false)
      );

      await this.$binding(
        "corporateBusiness",
        db.collection("corporateCoupons").where("deleted", "==", false)
      );

      await this.$binding("users", db.collection("appUsers"));
    } catch (error) {
      this.loading = false;
    }

    this.loading = false;
  },

  computed: {
    getCoupons() {
      return this.coupons.map((e) => {
        let creator = this.users.find((u) => u[".key"] == e.createdBy);
        e.createdByName = creator ? creator.name : "";
        let businessName = this.corporateBusiness.find(
          (i) => i[".key"] == e.corporateBusiness
        );

        e.businessName =
          e.corporateBusiness && businessName ? businessName.shortName : "ocho";
        return e;
      });
    },
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        })
        .catch((err) => {
          console.error("Async: Could not copy text: ", err);
        });
    },
    showInformation(item) {
      this.selectedCoupon = item;
      this.informationDialog = true;
    },
    switchControlChanged(data, couponId, coupon) {
      if (data && couponId) {
        this.saving = true;
        db.collection("coupons4")
          .doc(couponId)
          .update({ active: data.active })
          .then(() => {
            this.saving = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          })
          .catch(() => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;

            coupon.active = !coupon.active;
          });
      }
    },

    getSectionToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },

    async downloadReport(item) {
      this.loading = true;

      let token = await this.getSectionToken();

      let data = {
        code: item.code,
      };

      axios
        .post(
          `${process.env.VUE_APP_FUNCTIONS_URL}/callableCouponsV4UsedReport`,
          data,
          { headers: { Authorization: `Basic ${token}` }, responseType: "blob" }
        )
        .then((response) => {
          this.loading = false;
          const { data, headers } = response;
          const fileName = `${item.code}.xlsx`;

          const blob = new Blob([data], { type: headers["content-type"] });
          let dom = document.createElement("a");
          let url = window.URL.createObjectURL(blob);
          dom.href = url;
          dom.download = decodeURI(fileName);
          dom.style.display = "none";
          document.body.appendChild(dom);
          dom.click();
          dom.parentNode.removeChild(dom);
          window.URL.revokeObjectURL(url);
        })
        .catch(() => {
          this.loading = false;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },
    newCoupon() {
      this.randomKey = Math.random();
      this.type = "new";
      this.item = null;
      this.modalAddEdit = true;
    },
    editCoupon(item) {
      this.randomKey = Math.random();
      this.type = "edit";
      this.item = item;
      this.modalAddEdit = true;
    },
    handleSuccess(data) {
      this.snackbarText = data;
      this.snackbar = true;
      this.modalAddEdit = false;
    },
    handleModalSummary(collecton, array) {
      this.summaryCollection = collecton;
      this.idsArray = array;
      this.randomKey = Math.random();
      this.modalSummary = true;
    },
  },
  watch: {
    selectedCountry(countryId) {
      this.coupons = [];
      this.selectedCity = "";
      this.$binding(
        "cities",
        db
          .collection("cities")
          .where("deleted", "==", false)
          .where("countryId", "==", countryId)
      );
    },
    async selectedCity(cityId) {
      this.loading = true;
      await this.$binding(
        "coupons",
        db
          .collection("coupons4")
          .where("deleted", "==", false)
          .where("city", "==", cityId)
      );

      this.loading = false;
    },
  },
  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY");
    },
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },
    filterDuration(miliseconds) {
      return `${Math.round(miliseconds / 86400)} días`;
    },
    filterCouponType(type) {
      switch (type) {
        case "shipping":
          return "Envío";
        case "subtotal":
          return "Subtotal";
        case "total":
          return "Total";
      }
    },
    filterApplyDiscountTo(type) {
      switch (type) {
        case "subtotal":
          return "Subtotal";
        case "total":
          return "Total";
        case "shipping":
          return "Envio";
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped>
.inner-lottie {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
